<script lang="ts" setup>
import { VueInstance } from '@vueuse/core'
import { PropType } from 'vue'
import { CheckboxTypes } from '~/constants/checkboxTypes'
import { useCheckboxConfig } from '~/hooks/calendar/useCheckboxConfig'
import { TranslationKey } from '~/i18n/TranslationKeys'
import { ISelectable } from '~/types/Selectable'
import { EmitsEnum } from '~~/src/constants/emits'

const props = defineProps({
	items: { type: Object as PropType<ISelectable<TranslationKey, any>[]>, required: true },
	getChild: { type: Function as PropType<(pos: number) => any>, default: () => undefined },
	getChildProps: { type: Function as PropType<(pos: number) => any>, default: () => undefined },
	selectAll: { type: Boolean, default: false },
	maximumSelectionsReached: { type: Boolean, default: false },
	rowPaddingX: { type: String, default: 'px-3' },
	rowPaddingY: { type: String, default: 'py-4' },
	innerBackgroundColor: { type: String, default: 'bg-dark-blue-100' },
	dataId: { type: String, required: true },
	highlightItemIndex: { type: Number, required: false },
	checkboxClickableAreaClasses: { type: String, required: false },
	translateCheckboxes: { type: Boolean, default: true },
	testIds: {
		type: Object as PropType<{
			'accommodation-dropdown'?: string
			'search-input'?: string
			'room-type-list'?: string
			'select-all'?: string
			'tweak-left-button'?: string
			'tweak-right-button'?: string
			'remover-modifier-container'?: string
			'filters-container'?: string
			container?: string
		}>,
		default: () => ({}),
	},
})
const {
	items,
	getChild,
	getChildProps,
	selectAll,
	maximumSelectionsReached,
	rowPaddingX,
	rowPaddingY,
	dataId,
	innerBackgroundColor,
	translateCheckboxes,
	testIds,
} = toRefs(props)

const emit = defineEmits([EmitsEnum.Change])
const onChange = (position: number, value: boolean) => {
	items.value[position].selected = value
	emit(EmitsEnum.Change, items.value)
}

const getBoxSettings = (box: ISelectable<TranslationKey, any[]>) => {
	return useCheckboxConfig(ref(box.checkboxType || CheckboxTypes.REGULAR), ref(box.disabled || false))
}

// const getBoxBackground = (box: ISelectable<TranslationKey, any[]>) => box.disabled ? 'bg-dark-blue-50' : 'odd:bg-white even:bg-dark-blue-50 hover:bg-dark-blue-100'
const toggleAll = (condition: boolean) => {
	for (let i = 0; i < items.value.length; i++) {
		onChange(i, condition)
	}
}

const isCheckboxDisabled = computed(
	() => (box: ISelectable<TranslationKey, any>) => box.disabled || (maximumSelectionsReached.value && !box.selected)
)

const vm = getCurrentInstance()
const listReference = ref<HTMLElement>()
watch(
	() => props.items,
	async () => {
		// we need to wait for the DOM to be updated otherwise the last item is not available yet
		await nextTick()

		const updateIndex = props.highlightItemIndex
		if (vm && updateIndex !== undefined && updateIndex > -1) {
			const vueInstances: VueInstance[] = vm.refs[`checkbox-${updateIndex}`] as VueInstance[]

			const element: Element = vueInstances[0].$el
			const { top: elementTop } = element.getBoundingClientRect()!
			const { top: listTop } = listReference.value?.getBoundingClientRect()!
			const elementRelativePosition = elementTop - listTop

			const box = vm.parent.refs['scrollableChecklistReference']

			box.scrollTo({ top: elementRelativePosition, behavior: 'smooth' })

			element.classList.add('animate-highlightAndFade')
		}
	}
)

watch(selectAll, toggleAll)

defineExpose({
	toggleAll,
	onChange,
})
</script>

<template>
	<div
		class="block h-full rounded-md border border-dark-blue-100"
		ref="listReference"
		:data-testid="testIds?.['container']"
	>
		<template v-for="(box, index) in items">
			<CommonCheckbox
				:ref="`checkbox-${index}`"
				v-if="getChild!(index) != undefined && !box.hidden"
				:text="box.element"
				:clicked="box.selected"
				@toggle="(value: boolean) => onChange(index, value)"
				:disabled="isCheckboxDisabled(box)"
				:hint="box.hint"
				:checkbox-class="[rowPaddingX, rowPaddingY].join(' ')"
				:class="getBoxSettings(box).colors.value"
				:additional-infos="box.additionalInfos"
				:has-on-click="box.onClick != undefined"
				@click="box.onClick"
				:checkbox-type="box.checkboxType"
				:inner-background-color="innerBackgroundColor"
				:clickable-area-classes="checkboxClickableAreaClasses"
				:translate-checkbox="translateCheckboxes"
				:testIds="testIds"
			>
				<component
					:testIds="testIds"
					:is="getChild!(index)"
					v-bind="getChildProps?.(index)"
					:data-id="`${dataId}-${index}`"
				/>
			</CommonCheckbox>
			<CommonCheckbox
				:ref="`checkbox-${index}`"
				v-else-if="!box.hidden"
				:text="box.element"
				:clicked="box.selected"
				@toggle="(value: boolean) => onChange(index, value)"
				:class="getBoxSettings(box).colors.value"
				:hint="box.hint"
				:checkbox-class="[rowPaddingX, rowPaddingY].join(' ')"
				:disabled="isCheckboxDisabled(box)"
				:data-id="`${dataId}-${index}`"
				:additional-infos="box.additionalInfos"
				:has-on-click="box.onClick != undefined"
				@click="box.onClick"
				:checkbox-type="box.checkboxType"
				:translate-checkbox="translateCheckboxes"
				:testIds="testIds"
				:inner-background-color="innerBackgroundColor"
				:clickable-area-classes="checkboxClickableAreaClasses"
			/>
		</template>
	</div>
</template>
