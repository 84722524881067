import { SpSvg } from '~/autogen/SpSvg'
import { CheckboxTypes } from '~/constants/checkboxTypes'

export interface CheckboxTypeStatus {
    default: CheckboxSetup
    disabled: CheckboxSetup
}

export interface CheckboxSetup {
    classes: string
    icon?: {
        id: SpSvg
        fill: string
    }
}

export const buttonColorsConfig: { [k in CheckboxTypes]: CheckboxTypeStatus } =
    {
        [CheckboxTypes.REGULAR]: {
            default: {
                classes: `odd:bg-white even:bg-dark-blue-50 hover:bg-dark-blue-100`,
            },
            disabled: { classes: `bg-dark-blue-100` },
        },
        [CheckboxTypes.WARNING]: {
            default: {
                classes: `odd:bg-yellow-50 even:bg-yellow-100 hover:bg-yellow-200`,
                icon: {
                    id: SpSvg.BasicAlertCircle,
                    fill: 'fill-yellow-500',
                },
            },
            disabled: {
                classes: `bg-dark-blue-100`,
                icon: {
                    id: SpSvg.BasicAlertCircle,
                    fill: 'fill-yellow-500',
                },
            },
        },
    }

export const getCheckboxColorConfig = (key?: CheckboxTypes) =>
    buttonColorsConfig[key || CheckboxTypes.REGULAR] as CheckboxTypeStatus
