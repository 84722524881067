<script lang="ts" setup>
import { PropType } from 'vue'
import { CheckboxTypes } from '~/constants/checkboxTypes'
import { EmitsEnum } from '~/constants/emits'
import { useCheckboxConfig } from '~/hooks/calendar/useCheckboxConfig'
import { TranslationKey } from '~/i18n/TranslationKeys'
import { SpSvg } from '~~/src/autogen/SpSvg'
import { IconSizes } from '~~/src/constants/iconSizes'
import { TextSizes } from '~~/src/constants/textSizes'

const props = defineProps({
	clicked: { type: Boolean, default: false },
	text: { type: String as PropType<TranslationKey>, required: false },
	disabled: { type: Boolean, default: false },
	indeterminate: { type: Boolean, default: false },
	hint: { type: String as PropType<TranslationKey>, required: false },
	checkboxClass: { type: String, default: '' },
	additionalInfos: { type: String as PropType<TranslationKey>, required: false },
	innerBackgroundColor: { type: String, default: 'bg-dark-blue-100' },
	hasOnClick: { type: Boolean, default: false },
	checkboxType: { type: String as PropType<CheckboxTypes>, default: CheckboxTypes.REGULAR },
	noTruncate: { type: Boolean, default: false },
	clickableAreaClasses: { type: String, required: false },
	translateCheckbox: { type: Boolean, default: true },
})
const {
	clicked,
	text,
	disabled,
	indeterminate,
	hint,
	checkboxClass,
	additionalInfos,
	hasOnClick,
	checkboxType,
	noTruncate,
	clickableAreaClasses,
	translateCheckbox,
} = toRefs(props)

const slots = useSlots()
const hasSlot = computed(() => slots.default != undefined)
const { icon, iconFill } = useCheckboxConfig(checkboxType, disabled)

const internalState = ref(clicked.value)
watch(clicked, () => (internalState.value = clicked.value))

const emit = defineEmits([EmitsEnum.Click, EmitsEnum.Toggle])
const onClick = () => {
	if (!hasOnClick) {
		onToggle()
		return
	}

	if (disabled.value) {
		return
	}

	emit(EmitsEnum.Click)
}

const onToggle = () => {
	if (disabled.value) {
		return
	}

	internalState.value = !internalState.value
	emit(EmitsEnum.Toggle, internalState.value)
}

const hasClickableArea = computed(() => clickableAreaClasses?.value)
const cellClasses = computed(() => {
	let classes = disabled.value ? 'border-dark-blue-300 ' : 'border-main-blue-600 '

	if (indeterminate.value) {
		classes += 'bg-main-blue-200 '
	} else {
		classes += internalState.value ? 'bg-main-blue-400' : ''
	}

	return classes
})

const showHint = ref(false)
</script>

<template>
	<div class="relative">
		<div
			class="flex flex-row items-stretch justify-between gap-2 py-2"
			:class="checkboxClass + ' ' + (disabled ? '' : 'cursor-pointer')"
			@click="onClick"
		>
			<div class="flex items-center gap-2">
				<div
					v-if="hasClickableArea"
					:class="clickableAreaClasses"
					class="absolute left-0 top-0"
					@click.stop="onToggle"
				></div>
				<div class="h-3 w-3 rounded border-2 px-1" :class="cellClasses" @click.stop="onToggle"></div>

				<div v-if="hasOnClick" class="h-full border-l border-dark-blue-200" />
				<CommonIcon v-if="icon != undefined" :fill="iconFill" :icon-name="icon" :icon-size="IconSizes.XS" />
				<CommonText
					v-if="text != undefined"
					class="whitespace-pre-line"
					:text="text"
					:translate="translateCheckbox"
					:text-size="TextSizes.BODY_REGULAR"
					:class="{ 'text-dark-blue-400': disabled, 'line-clamp-2': !noTruncate }"
				/>
			</div>
			<div class="flex items-center gap-2">
				<CommonText
					v-if="additionalInfos != undefined"
					:text="additionalInfos"
					:text-size="TextSizes.BODY_REGULAR"
					:class="disabled ? 'text-dark-blue-400' : ''"
				/>
				<CommonTooltip v-if="hint != undefined" :text="hint" :show="showHint" :width="'w-32'">
					<CommonIcon
						:icon-name="SpSvg.BasicHelp"
						:icon-size="IconSizes.S"
						@mouseenter="showHint = true"
						@mouseleave="showHint = false"
					/>
				</CommonTooltip>
			</div>
		</div>
		<AnimationCollapse>
			<div v-if="hasSlot && internalState" class="truncate bg-dark-blue-100 p-3">
				<slot></slot>
			</div>
		</AnimationCollapse>
	</div>
</template>
