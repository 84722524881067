import { Ref } from 'nuxt/dist/app/compat/capi'
import { SpSvg } from '~/autogen/SpSvg'
import { getCheckboxColorConfig } from '~/config/checkboxColorsConfig'
import { CheckboxTypes } from '~/constants/checkboxTypes'

export const useCheckboxConfig = (
    type: Ref<CheckboxTypes>,
    disabled: Ref<Boolean>
) => {
    const currentConfig = computed(() => {
        const checkboxConfig = getCheckboxColorConfig(type.value)

        // disabled state
        if (disabled.value) {
            return checkboxConfig.disabled
        }
        return checkboxConfig.default
    })

    const colors = computed((): string => currentConfig.value.classes)
    const icon = computed((): SpSvg | undefined => currentConfig.value.icon?.id)
    const iconFill = computed(
        (): string | undefined => currentConfig.value.icon?.fill
    )

    return { colors, icon, iconFill }
}
